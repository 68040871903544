<script>
import Layout from "@/router/layouts/main";
import appConfig from "@/app.config";
import PageHeader from "@/components/page-header";
import moment from "moment";
import axios from "axios";
import Swal from "sweetalert2";
import { encryptData } from "@/helpers/crypt.js";
import { containsDisallowedCharacters } from "../../../../utils/check-disallowed-character";

/**
 * Dashboard Component
 */

export default {
  page: {
    title: "Tambah Jadwal Asesmen Combo",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  props: {
    dataPeserta: {
      type: String,
    },
  },
  components: {
    Layout,
    PageHeader,
  },
  data() {
    return {
      data_peserta: [],
      data_jadwal: [],

      form_data: {
        form_petugas: [
          {
            jp_txt_petugas: "",
            jp_txt_tugas: "",
            jadwal_idjadwal: null,
            m_user_id_petugas: "",
            m_user_nip_petugas: "",
          },
        ],
        form_jobtarget: [
          {
            id_jobtarget: "",
            nama: "",
            id_jenisasesmen: "",
          },
        ],
        j_metode: "Assessment",
        m_unit_idm_unit: "",
        j_txt_unit: "",
        m_lokasi_idm_lokasi: "",
        j_txt_lokasi: "", //
        j_metode_jadwal: "",
        m_user_id_ptl: "",
        m_user_nip_ptl: "",
        j_txt_ptl: "",
        j_json_petugas: "",
        j_tanggal_mulai: "", //
        j_tanggal_sampai: "", //
        j_jam_mulai: "", //
        j_jam_sampai: "", //
        j_mulai_penilaian: "",
        j_akhir_penilaian: "",
        j_impact: "NO",
        j_keterangan: "",
        j_agreement: "",
        m_jadwal_kegiatan_idm_kegiatan: "", //
        j_txt_kegiatan: "",
        m_dirkom_idm_dirkom: "",
        j_txt_dirkom: "",
        j_tipe: "",
        m_batre_test_bt_id: "",
        j_txt_batre_test: "",
        j_materi: "",
        user_id: localStorage.getItem("session_user_id"),
        user_name: localStorage.getItem("session_name"),
        m_id_subkegiatan: "",
        m_nama_kegiatan: "",
        j_bentuk_dinamika: "",
        j_bentuk_penilaian: "",
        j_json_alattes: "",
        unit_peserta: [
          {
            m_unit_slug: "",
            m_unit_idm_unit: "",
            jadwal_idjadwal: null,
            ju_txt_unit: "",
            jumlah_diundang: "",
          },
        ],
        checklist: [],
        m_id_materi: "",
        is_checked_job: ""
      },

      form_data_fix: [],
      temp_form: [],
      nama_grup: "",

      option_unit: [],
      selected_unit: "",

      option_lokasi: [],
      checkedLokasi: false,
      selected_lokasi: "",

      selected_petugas: [],
      selected_tugas: [],

      option_tugas: [],

      temp_id_jadwal: "",
      selected_metode: "",

      option_kegiatan: [],
      selected_kegiatan: "",

      option_jenis_asesmen: [],
      seleceted_jenis_asesmen: "",

      option_batre_test: [],
      seleceted_batre_test: "",

      option_alat_test: [],

      option_materi: [],
      seleceted_materi: "",

      option_checklist: [],
      selected_checklist: "",

      option_sub_kegiatan: [],
      selected_sub_kegiatan: "",

      option_ptl: [],
      selected_ptl: [],

      json_fix_peserta: [],

      form_unit: [],
      selected_unit_peserta: [],
      selected_jumlah_peserta: [],

      //Jenis Asesmen
      readOnlyJA: true,
      //Materi
      readOnlyMateri: true,

      lokasi_text: "",
      json_petugas: "",

      selected_jobtarget: [],
      option_jobtarget: [],
      is_show_job_target_list: false,
      is_show_opsi_job_target: false,
    };
  },

  mounted() {
    let self = this;
    self.searchUnit(true);
    self.searchLokasi(true);
    self.searchKegiatan(true);
    // self.searchJenisAsesmen(true);
    self.searchBatreTest(true);
    // self.searchMateri(true);
    self.searchPTL(true);
    self.searchTugas();
    self.searchSubKegiatan(true);
  },

  created() {
    // this.data_peserta = JSON.parse(window.history.state.data_peserta);
    // this.data_jadwal = JSON.parse(window.history.state.data_jadwal);

    // this.form_data.m_user_id_ptl = this.data_jadwal.m_user_id_ptl;
    // this.form_data.m_user_nip_ptl = this.data_jadwal.m_user_nip_ptl;
    // this.form_data.j_txt_ptl = this.data_jadwal.j_txt_ptl;
    // this.form_data.j_json_petugas = this.data_jadwal.j_json_petugas;
    // this.json_petugas = JSON.parse(this.data_jadwal.j_json_petugas);

    console.log(this.data_peserta);
  },
  methods: {
    encryptedUrl(params) {
      const encryptedText = encryptData(params);
      return encryptedText;
    },
    addForm() {
      this.form_data.form_petugas.push({
        jp_txt_petugas: "",
        jp_txt_tugas: "",
        jadwal_idjadwal: null,
        m_user_id_petugas: "",
        m_user_nip_petugas: "",
      });
    },

    onSearchSubKegiatan(search, loading) {
      if (search.length) {
        loading(true);
        this.searchSubKegiatan(loading, search, this);
      }
    },
    searchSubKegiatan: function (loading, search) {
      let self = this;
      var config = {
        method: "get",
        url: process.env.VUE_APP_BACKEND_URL_VERSION + "referensi/sub-kegiatan",
        params: {
          keyword: search,
        },
        headers: {
          Accept: "application/json",
          Authorization: "Bearer " + localStorage.access_token,
        },
      };
      axios(config).then((response) => {
        if (response.data.meta.code == 200) {
          self.option_sub_kegiatan = response.data.data.referensi;
          loading(false);
        }
      });
    },
    onChangeSubKegiatan() {
      let self = this;
      self.form_data.m_id_subkegiatan = self.selected_sub_kegiatan.id_subkegiatan;
      self.form_data.m_nama_kegiatan = self.selected_sub_kegiatan.nama_subkegiatan;
    },

    clearForm(index) {
      this.form_data.form_petugas[index].jp_txt_petugas = "";
      this.form_data.form_petugas[index].jp_txt_tugas = "";
      this.form_data.form_petugas[index].jadwal_idjadwal = null;
      this.form_data.form_petugas[index].m_user_id_petugas = "";
      this.form_data.form_petugas[index].m_user_nip_petugas = "";
      this.selected_petugas.splice(index, 1);
      this.selected_tugas.splice(index, 1);
      this.form_data.form_petugas.splice(index, 1);
    },
    onChangePetugas(index) {
      this.form_data.form_petugas[index].m_user_id_petugas = this.selected_petugas[index].iduser;
      this.form_data.form_petugas[index].m_user_nip_petugas = this.selected_petugas[index].u_nip;
      this.form_data.form_petugas[index].jp_txt_petugas = this.selected_petugas[index].u_nama;
    },
    onChangeTugas(index) {
      this.form_data.form_petugas[index].jp_txt_tugas = this.selected_tugas[index].tugas_txt;
    },
    fullDateTimeFormat(datetime) {
      return moment(datetime).format("YYYY-MM-DD HH:mm");
    },

    //Dropdown Unit
    onSearchUnit(search, loading) {
      if (search.length) {
        loading(true);
        this.searchUnit(loading, search, this);
      }
    },
    searchUnit: function (loading, search) {
      let self = this;
      var config = {
        method: "get",
        url: process.env.VUE_APP_BACKEND_URL_VERSION + "referensi/unit",
        params: {
          keyword: search,
        },
        headers: {
          Accept: "application/json",
          Authorization: "Bearer " + localStorage.access_token,
        },
      };
      axios(config).then((response) => {
        if (response.data.meta.code == 200) {
          self.option_unit = response.data.data.referensi;
          loading(false);
        }
      });
    },
    onChangeUnit() {
      let self = this;
      self.form_data.m_unit_idm_unit = self.selected_unit.m_unit_idm_unit;
      self.form_data.j_txt_unit = self.selected_unit.ju_txt_unit;
    },

    //Pilih Lokasi
    onSearchLokasi(search, loading) {
      if (search.length) {
        loading(true);
        this.searchLokasi(loading, search, this);
      }
    },
    searchLokasi: function (loading, search) {
      let self = this;
      var config = {
        method: "get",
        url: process.env.VUE_APP_BACKEND_URL_VERSION + "referensi/search-lokasi",
        params: {
          keyword: search,
        },
        headers: {
          Accept: "application/json",
          Authorization: "Bearer " + localStorage.access_token,
        },
      };
      axios(config).then((response) => {
        if (response.data.meta.code == 200) {
          self.option_lokasi = response.data.data.referensi;
          loading(false);
        }
      });
    },
    checkLokasi() {
      let self = this;
      this.$nextTick(() => {
        console.log(self.checkedLokasi);
      });
    },
    onChangeLokasi() {
      let self = this;
      self.form_data.m_lokasi_idm_lokasi = self.selected_lokasi.ml_id;
      self.form_data.j_txt_lokasi = self.selected_lokasi.ml_nama;
    },
    // submitForm() {
    //   console.log(this.form_data);
    // },

    //Kegiatan
    onSearchKegiatan(search, loading) {
      if (search.length) {
        loading(true);
        this.searchKegiatan(loading, search, this);
      }
    },
    searchKegiatan: function (loading, search) {
      let self = this;
      var config = {
        method: "get",
        url: process.env.VUE_APP_BACKEND_URL_VERSION + "referensi/kegiatan",
        params: {
          keyword: search,
          tipe: "ass",
        },
        headers: {
          Accept: "application/json",
          Authorization: "Bearer " + localStorage.access_token,
        },
      };
      axios(config).then((response) => {
        if (response.data.meta.code == 200) {
          self.option_kegiatan = response.data.data.referensi;
          loading(false);
        }
      });
    },
    onChangeKegiatan() {
      let self = this;
      self.form_data.m_jadwal_kegiatan_idm_kegiatan = self.selected_kegiatan.id;
      self.form_data.j_txt_kegiatan = self.selected_kegiatan.kegiatan;
      self.searchJenisAsesmen(true);
      self.form_data.m_dirkom_idm_dirkom = null;
      self.seleceted_jenis_asesmen = null;
      self.readOnlyJA = false;
    },

    //Jenis Asesmen
    onSearchJenisAsesmen(search, loading) {
      if (search.length) {
        loading(true);
        this.searchJenisAsesmen(loading, search, this);
      }
    },
    searchJenisAsesmen: function (loading, search) {
      let self = this;
      var config = {
        method: "get",
        url: process.env.VUE_APP_BACKEND_URL_VERSION + "referensi/jenis-asesmen",
        params: {
          keyword: search,
          id_kegiatan: self.selected_kegiatan.id,
        },
        headers: {
          Accept: "application/json",
          Authorization: "Bearer " + localStorage.access_token,
        },
      };
      axios(config).then((response) => {
        if (response.data.meta.code == 200) {
          self.option_jenis_asesmen = response.data.data.referensi;
          loading(false);
        }
      });
    },
    onChangeJenisAsesmen() {
      let self = this;
      self.form_data.m_dirkom_idm_dirkom = self.seleceted_jenis_asesmen.id;
      self.form_data.j_txt_dirkom = self.seleceted_jenis_asesmen.nama_jenisasesmen;
      self.is_show_opsi_job_target = true;
      self.searchJobTarget();
      self.onSearchMateri();
      self.searchCheklist();
      self.form_data.j_materi = null;
      self.seleceted_materi = null;
      self.readOnlyMateri = false;
    },

    //Batre Test
    onSearchBatreTest(search, loading) {
      if (search.length) {
        loading(true);
        this.searchBatreTest(loading, search, this);
      }
    },
    searchBatreTest: function (loading, search) {
      let self = this;
      var config = {
        method: "get",
        url: process.env.VUE_APP_BACKEND_URL_VERSION + "referensi/batre-test",
        params: {
          keyword: search,
          tipe: "AC",
        },
        headers: {
          Accept: "application/json",
          Authorization: "Bearer " + localStorage.access_token,
        },
      };
      axios(config).then((response) => {
        if (response.data.meta.code == 200) {
          self.option_batre_test = response.data.data.referensi;

          loading(false);
        }
      });
    },
    onChangeBatreTest() {
      let self = this;
      self.form_data.m_batre_test_bt_id = self.seleceted_batre_test.bt_id;
      self.form_data.j_txt_batre_test = self.seleceted_batre_test.bt_nama;
      self.getAlatTes();
    },

    //alattes
    getAlatTes() {
      let self = this;
      var config = {
        method: "get",
        url: process.env.VUE_APP_BACKEND_URL_VERSION + "referensi/batre-alat-test",
        headers: {
          Accept: "application/json",
          Authorization: "Bearer " + localStorage.access_token,
        },
        params: {
          id_batretest: self.seleceted_batre_test.bt_id,
        },
      };

      axios(config)
        .then((response) => {
          let response_data = response.data;
          if (response_data.meta.code == 200) {
            self.option_alat_test = response_data.data.referensi;
            self.form_data.j_json_alattes = self.option_alat_test;
          }
        })
        .catch((errors) => {
          console.log(errors);
        });
    },

    //materi
    onSearchMateri(search, loading) {
      if (search && search.length) {
        loading(true);
      }
      this.searchMateri(loading, search, this);
    },
    searchMateri: function (loading, search) {
      let self = this;
      var config = {
        method: "get",
        url: process.env.VUE_APP_BACKEND_URL_VERSION + "referensi/materi",
        params: {
          keyword: search,
          id_jenis_asesmen: self.seleceted_jenis_asesmen.id,
        },
        headers: {
          Accept: "application/json",
          Authorization: "Bearer " + localStorage.access_token,
        },
      };
      axios(config).then((response) => {
        if (response.data.meta.code == 200) {
          self.option_materi = response.data.data.referensi;
          loading(false);
        }
      });
    },
    onChangeMateri() {
      let self = this;
      self.form_data.j_materi = self.seleceted_materi.mm_nama;
      self.form_data.m_id_materi = self.seleceted_materi.mm_id;
    },

    //checklist
    onSearchCheklist(search, loading) {
      if (search.length) {
        loading(true);
        this.searchCheklist(loading, search, this);
      }
    },
    searchCheklist: function (loading, search) {
      let self = this;
      var config = {
        method: "get",
        url: process.env.VUE_APP_BACKEND_URL_VERSION + "referensi/checklist-asesmen",
        params: {
          keyword: search,
          id_jenis_asesmen: self.form_data.m_dirkom_idm_dirkom,
        },
        headers: {
          Accept: "application/json",
          Authorization: "Bearer " + localStorage.access_token,
        },
      };
      axios(config).then((response) => {
        if (response.data.meta.code == 200) {
          self.form_data.checklist = response.data.data.referensi;
          loading(false);
        }
      });
    },

    submitForm() {
      let self = this;
      console.log(self.form_data.form_petugas);
      self.form_data.j_json_petugas = JSON.stringify(self.form_data.form_petugas);
      var temp_form = JSON.parse(JSON.stringify(this.form_data));

      self.form_data_fix.push(temp_form);

      self.form_data = {
        form_petugas: [
          {
            jp_txt_petugas: "",
            jp_txt_tugas: "",
            jadwal_idjadwal: null,
            m_user_id_petugas: "",
            m_user_nip_petugas: "",
          },
        ],
        form_jobtarget: [
          {
            id_jobtarget: "",
            nama: "",
            id_jenisasesmen: "",
          },
        ],
        j_metode: "Assessment",
        m_unit_idm_unit: "",
        j_txt_unit: "",
        m_lokasi_idm_lokasi: "",
        j_txt_lokasi: "", //
        j_metode_jadwal: "",
        m_user_id_ptl: "",
        m_user_nip_ptl: "",
        j_txt_ptl: "",
        j_json_petugas: "",
        j_tanggal_mulai: "", //
        j_tanggal_sampai: "", //
        j_jam_mulai: "", //
        j_jam_sampai: "", //
        j_mulai_penilaian: "",
        j_akhir_penilaian: "",
        j_impact: "NO",
        j_keterangan: "",
        j_agreement: "",
        m_jadwal_kegiatan_idm_kegiatan: "", //
        j_txt_kegiatan: "",
        m_dirkom_idm_dirkom: "",
        j_txt_dirkom: "",
        j_tipe: "",
        m_batre_test_bt_id: "",
        j_txt_batre_test: "",
        j_materi: "",
        user_id: localStorage.getItem("session_user_id"),
        user_name: localStorage.getItem("session_name"),
        j_bentuk_dinamika: "",
        j_bentuk_penilaian: "",
        j_json_alattes: "",
        m_id_subkegiatan: "",
        m_nama_kegiatan: "",
        unit_peserta: [
          {
            m_unit_slug: "",
            m_unit_idm_unit: "",
            jadwal_idjadwal: null,
            ju_txt_unit: "",
            jumlah_diundang: "",
          },
        ],
        checklist: [],
      };
      self.seleceted_batre_test = [];
      self.seleceted_jenis_asesmen = [];
      self.seleceted_materi = [];
      self.selected_checklist = [];
      self.selected_jumlah_peserta = [];
      self.selected_kegiatan = [];
      self.selected_lokasi = [];
      self.selected_metode = [];
      self.selected_petugas = [];
      self.selected_ptl = [];
      self.selected_tugas = [];
      self.selected_unit = [];
      self.selected_unit_peserta = [];
      self.selected_sub_kegiatan = [];
      //   Swal.fire({
      //     title: '<i class="fas fa-spinner fa-spin"></i>',
      //     text: "Loading...",
      //     showConfirmButton: false,
      //   });
      //   var config = {
      //     method: "post",
      //     url:
      //       process.env.VUE_APP_BACKEND_URL_VERSION +
      //       "master/jadwal-pegpog/store-jadwal-asesmen-center",
      //     data: self.form_data,
      //     headers: {
      //       Accept: "application/json",
      //       Authorization: "Bearer " + localStorage.access_token,
      //     },
      //   };
      //   axios(config)
      //     .then((response) => {
      //       if (response.data.meta.code == 200) {
      //         // self.storeDataPetugas(response.data.data[0].idjadwal);
      //         self.temp_id_jadwal = response.data.data[0].idjadwal;
      //         self.storeDataUnit(self.temp_id_jadwal);
      //       }
      //     })
      //     .catch((error) => {
      //       Swal.close();
      //       console.log(error);
      //     });
    },
    addFormUnit() {
      let self = this;
      self.form_data.unit_peserta.push({
        m_unit_slug: "",
        m_unit_idm_unit: "",
        jadwal_idjadwal: null,
        ju_txt_unit: "",
        jumlah_diundang: "",
      });
    },
    clearFormUnit(index) {
      let self = this;
      self.form_data.unit_peserta[index].m_unit_slug = "";
      self.form_data.unit_peserta[index].m_unit_idm_unit = "";
      self.form_data.unit_peserta[index].jadwal_idjadwal = null;
      self.form_data.unit_peserta[index].ju_txt_unit = "";
      self.form_data.unit_peserta[index].jumlah_diundang = "";
      self.selected_unit_peserta.splice(index, 1);
      self.selected_jumlah_peserta.splice(index, 1);
      self.form_data.unit_peserta.splice(index, 1);
    },

    onSearchPTL(search, loading) {
      if (search.length) {
        loading(true);
        this.searchPTL(loading, search, this);
      }
    },
    searchPTL: function (loading, search) {
      let self = this;
      var config = {
        method: "get",
        url: process.env.VUE_APP_BACKEND_URL_VERSION + "referensi/search-user",
        params: {
          keyword: search,
        },
        headers: {
          Accept: "application/json",
          Authorization: "Bearer " + localStorage.access_token,
        },
      };
      axios(config).then((response) => {
        if (response.data.meta.code == 200) {
          self.option_ptl = response.data.data.referensi;
          loading(false);
        }
      });
    },

    searchTugas: function (search) {
      let self = this;
      var config = {
        method: "get",
        url: process.env.VUE_APP_BACKEND_URL_VERSION + "referensi/tugas",
        params: {
          keyword: search,
        },
        headers: {
          Accept: "application/json",
          Authorization: "Bearer " + localStorage.access_token,
        },
      };
      axios(config).then((response) => {
        if (response.data.meta.code == 200) {
          self.option_tugas = response.data.data.referensi;
        }
      });
    },

    validasi() {
      if (this.form_data.j_txt_unit && this.form_data.m_unit_idm_unit == "") {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Unit Tidak Boleh Kosong",
        });
        return false;
      }

      if (this.form_data.j_txt_lokasi == "") {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Lokasi Jadwal Tidak Boleh Kosong",
        });
        return false;
      }
      if (this.form_data.j_metode_jadwal == "") {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Metode Jadwal Tidak Boleh Kosong",
        });
        return false;
      }

      if (this.form_data.j_txt_ptl == "") {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Ptl Tidak Boleh Kosong",
        });
        return false;
      }
      if (this.form_data.j_txt_tgl == "") {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Tanggal Tidak Boleh Kosong",
        });
        return false;
      }

      if (this.form_data.j_tanggal_mulai == "") {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Tanggal Mulai Tidak Boleh Kosong",
        });
        return false;
      }

      if (this.form_data.j_tanggal_sampai == "") {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Tanggal Sampai Tidak Boleh Kosong",
        });
        return false;
      }

      if (this.form_data.j_jam_mulai == "") {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Jam Mulai Tidak Boleh Kosong",
        });
        return false;
      }
      if (this.form_data.j_jam_sampai == "") {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Jam Sampai Tidak Boleh Kosong",
        });
        return false;
      }
      if (this.form_data.j_mulai_penilaian == "" && this.form_data.j_akhir_penilaian == "") {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "jam Penilaian Tidak Boleh Kosong",
        });
        return false;
      }
      if (this.form_data.j_impact == "") {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Adverse Impact Tidak Boleh Kosong",
        });
        return false;
      }
      // j_keterangan
      if (this.form_data.j_keterangan == "") {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Keterangan Tidak Boleh Kosong",
        });
        return false;
      }
      if (this.form_data.j_agreement == "") {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Agreement Tidak Boleh Kosong",
        });
        return false;
      }
      // j_txt_kegiatan
      if (this.form_data.j_txt_kegiatan == "") {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Kegiatan Tidak Boleh Kosong",
        });
        return false;
      }
      // j_txt_dirkom
      if (this.form_data.j_txt_dirkom == "") {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "jenis assesmen Tidak Boleh Kosong",
        });
        return false;
      }
      if (this.form_data.j_tipe == "") {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "tipe ac Tidak Boleh Kosong",
        });
        return false;
      }
      if (this.form_data.j_txt_batre_test == "" || this.form_data.m_batre_test_bt_id == "") {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Batre Test Tidak Boleh Kosong",
        });
        return false;
      }
      if (this.j_json_alattes == "") {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Alat Tes Tidak Boleh Kosong",
        });
        return false;
      }
      if (this.form_data.j_materi == "") {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Materi Tidak Boleh Kosong",
        });
        return false;
      }
      if (this.form_data.j_bentuk_dinamika == "") {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Bentuk Dinamika Tidak Boleh Kosong",
        });
        return false;
      }
      if (containsDisallowedCharacters(this.form_data.j_keterangan)) {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Terdapat karakter yang tidak diperbolehkan!",
        });
        return false; // Exit the function if validation fails
      }
      if (containsDisallowedCharacters(this.form_data.j_agreement)) {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Terdapat karakter yang tidak diperbolehkan!",
        });
        return false; // Exit the function if validation fails
      }
      // j_bentuk_penilaian
      if (this.form_data.j_bentuk_penilaian == "") {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Bentuk Penilaian Tidak Boleh Kosong",
        });
        return false;
      } else {
        Swal.fire({
          title: "Apakah Anda Yakin?",
          text: "Data Akan Disimpan",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          cancelButtonText: "Batal",
          confirmButtonText: "Ya, Simpan!",
        }).then((result) => {
          if (result.isConfirmed) {
            this.submitForm();
          }
        });
      }
    },

    storeDataCombo() {
      Swal.fire({
        title: '<i class="fas fa-spinner fa-spin"></i>',
        text: "Loading...",
        showConfirmButton: false,
      });
      let self = this;
      var config = {
        method: "post",
        url: process.env.VUE_APP_BACKEND_URL_VERSION + "master/penjadwalan/asesmen-center/store-jadwal-combo",
        data: {
          jadwal: self.form_data_fix,
          nama_grup: self.nama_grup,
        },
        headers: {
          Accept: "application/json",
          Authorization: "Bearer " + localStorage.access_token,
        },
      };
      axios(config).then((response) => {
        if (response.data.meta.code == 200) {
          Swal.close();
          Swal.fire({
            icon: "success",
            title: "Berhasil",
            text: "Berhasil Menambahkan Jadwal",
            timer: 2000,
            timerProgressBar: true,
            showCancelButton: false,
            showConfirmButton: false,
          }).then((result) => {
            /* Read more about handling dismissals below */
            if (result.dismiss === Swal.DismissReason.timer) {
              Swal.close();
              let response_data = response.data;
              let response_data_fix = response_data.data[0];
              self.$router.push({
                name: "detail-asesmen-center",
                params: {
                  id: this.encryptedUrl(response_data_fix.toString()),
                },
              });
            }
          });
        }
      });
    },

    storeChecklist(idjadwal) {
      let self = this;

      for (let index = 0; index < self.form_unit.length; index++) {
        self.form_unit[index].jadwal_idjadwal = idjadwal;
      }
      var config = {
        method: "post",
        url: process.env.VUE_APP_BACKEND_URL_VERSION + "master/penjadwalan/asesmen-center/store-jadwal-combo",
        data: {
          id_jadwal: self.temp_id_jadwal,
          json_checklist: self.option_checklist,
        },
        headers: {
          Accept: "application/json",
          Authorization: "Bearer " + localStorage.access_token,
        },
      };
      axios(config).then((response) => {
        if (response.data.meta.code == 200) {
          Swal.fire({
            icon: "success",
            title: "Berhasil",
            text: "Berhasil Menambahkan Jadwal",
            timer: 2000,
            timerProgressBar: true,
            showCancelButton: false,
            showConfirmButton: false,
          }).then((result) => {
            /* Read more about handling dismissals below */
            if (result.dismiss === Swal.DismissReason.timer) {
              Swal.close();
              self.$router.push({
                name: "all-asesmen-center",
              });
            }
          });
        }
      });
    },

    onChangeUnitPeserta(index) {
      let self = this;
      self.form_data.unit_peserta[index].ju_txt_unit = self.selected_unit_peserta[index].ju_txt_unit;
      self.form_data.unit_peserta[index].m_unit_idm_unit = self.selected_unit_peserta[index].m_unit_idm_unit;
      self.form_data.unit_peserta[index].m_unit_slug = self.selected_unit_peserta[index].m_unit_slug;
    },

    onChangePTL() {
      let self = this;
      self.form_data.m_user_id_ptl = self.selected_ptl.iduser;
      self.form_data.m_user_nip_ptl = self.selected_ptl.u_nip;
      self.form_data.j_txt_ptl = self.selected_ptl.u_nama;
    },

    removeCart(index) {
      let self = this;
      self.form_data_fix.splice(index, 1);
    },

    onChangeTglSampai(date) {
      const currentDate = new Date(date);
      currentDate.setDate(currentDate.getDate() + 4);
      this.form_data.j_akhir_penilaian = currentDate.toISOString().slice(0, 10);

      const currentDate2 = new Date(date);
      currentDate2.setDate(currentDate2.getDate() + 1);
      this.form_data.j_mulai_penilaian = currentDate2.toISOString().slice(0, 10);
    },

    searchJobTarget: function (search) {
      let self = this;
      var config = {
        method: "get",
        url: process.env.VUE_APP_BACKEND_URL_VERSION + "referensi/job-target",
        params: {
          keyword: search,
          id_jenis_asesmen: self.seleceted_jenis_asesmen.id
        },
        headers: {
          Accept: "application/json",
          Authorization: "Bearer " + localStorage.access_token,
        },
      };
      axios(config).then((response) => {
        if (response.data.meta.code == 200) {
          self.option_jobtarget = response.data.data.referensi;
        }
      });
    },

    onChangeJobTarget(index) {
      this.form_data.form_jobtarget[index].id_jobtarget = this.selected_jobtarget[index].id_jobtarget;
      this.form_data.form_jobtarget[index].nama = this.selected_jobtarget[index].nama;
      this.form_data.form_jobtarget[index].id_jenisasesmen = this.selected_jobtarget[index].id_jenisasesmen;
    },

    addFormJobTarget() {
      this.form_data.form_jobtarget.push({
        id_jobtarget: "",
        nama: "",
        id_jenisasesmen: "",
      });
    },
    clearFormJobTarget(index) {
      this.selected_jobtarget.splice(index, 1);
      this.form_data.form_jobtarget.splice(index, 1);
    },
    checkJobTarget() {
      let self = this;
      this.$nextTick(() => {
        if (self.form_data.is_checked_job == 1) {
          self.is_show_job_target_list = true;
        } else {
          self.is_show_job_target_list = false;
        }
      });
    },
  },
};
</script>
<template>
  <Layout>
    <PageHeader
      :title="title"
      :items="items"
    />
    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <div class="row">
              <h4>Tambah Jadwal Asesmen Center Combo</h4>
            </div>
            <div class="row mt-4">
              <div class="col-5">
                <!-- Form Metode -->
                <div class="row">
                  <div class="col-md-12">
                    <b-form-group
                      class="mb-3"
                      label="Metode"
                      label-for="form-metode"
                    >
                      <b-form-input
                        id="form-metode"
                        class="bg-secondary text-white"
                        type="text"
                        readonly
                        v-model="form_data.j_metode"
                      ></b-form-input>
                    </b-form-group>
                  </div>
                </div>
                <!-- Form Unit Pelaksana -->
                <div class="row">
                  <div class="col-md-12">
                    <b-form-group
                      class="mb-3"
                      label="Unit Pelaksana"
                      label-for="form-unit-pelaksana"
                    >
                      <v-select
                        :options="option_unit"
                        label="m_unit_slug"
                        v-model="selected_unit"
                        placeholder="-Pilih Unit-"
                        @search="onSearchUnit"
                        @update:modelValue="onChangeUnit()"
                      ></v-select>
                    </b-form-group>
                  </div>
                </div>
                <!-- Form Lokasi -->
                <div class="row">
                  <div class="col-md-2">
                    <b-form-group
                      class="mb-3"
                      label="Lokasi"
                      label-for="lokasi"
                    >
                      <div class="form-check">
                        <input
                          class="form-check-input"
                          type="checkbox"
                          v-model="checkedLokasi"
                          value="true"
                          id="autoSizingCheck"
                          @change="checkLokasi($event)"
                        />
                        <label
                          class="form-check-label"
                          for="autoSizingCheck"
                        > Isi Text </label>
                      </div>
                    </b-form-group>
                  </div>
                  <div class="col-md-10">
                    <b-form-group
                      class="mb-3"
                      v-if="!checkedLokasi"
                      label="Pilih Lokasi"
                      label-for="lokasi"
                    >
                      <v-select
                        :options="option_lokasi"
                        label="ml_nama"
                        v-model="selected_lokasi"
                        placeholder="-Pilih Lokasi-"
                        @search="onSearchLokasi"
                        @update:modelValue="onChangeLokasi()"
                      ></v-select>
                    </b-form-group>
                    <b-form-group
                      class="mb-3"
                      v-else
                      label="Lokasi"
                      label-for="lokasiText"
                    >
                      <b-form-input
                        id="lokasiText"
                        placeholder="Masukkan Nama Lokasi"
                        type="text"
                        v-model="form_data.j_txt_lokasi"
                      ></b-form-input>
                    </b-form-group>
                  </div>
                </div>
                <!-- Form Lokasi -->
                <div class="row">
                  <div class="col-md-12">
                    <b-form-group
                      class="mb-3"
                      label="Metode Jadwal"
                      label-for="form-metode"
                    >
                      <v-select
                        :options="['Online', 'Offline']"
                        v-model="form_data.j_metode_jadwal"
                        placeholder="-Pilih Metode Jadwal-"
                      ></v-select>
                    </b-form-group>
                  </div>
                </div>
                <!-- Form PTL -->
                <div class="row">
                  <div class="col-md-12">
                    <b-form-group
                      class="mb-3"
                      label="PTL"
                      label-for="ptl"
                    >
                      <v-select
                        :options="option_ptl"
                        label="label"
                        v-model="selected_ptl"
                        placeholder="-Pilih PTL-"
                        @search="onSearchPTL"
                        @update:modelValue="onChangePTL()"
                      ></v-select>
                    </b-form-group>
                  </div>
                </div>
                <!-- Form Metode -->
                <div
                  class="row"
                  v-for="(form, k) in json_petugas"
                  :key="k"
                >
                  <div class="col-md-6">
                    <b-form-group
                      class="mb-3"
                      label="Petugas"
                      label-for="form-petugas"
                    >
                      <b-form-input
                        id="tgl-petugas"
                        class="bg-secondary text-white"
                        type="text"
                        readonly
                        v-model="form.jp_txt_petugas"
                      ></b-form-input>
                    </b-form-group>
                  </div>
                  <div class="col-md-6">
                    <b-form-group
                      class="mb-3"
                      label="Tugas"
                      label-for="form-tugas"
                    >
                      <b-form-input
                        id="form-tugas"
                        class="bg-secondary text-white"
                        type="text"
                        readonly
                        v-model="form.jp_txt_tugas"
                      ></b-form-input>
                    </b-form-group>
                  </div>
                </div>
                <div
                  class="row"
                  v-for="(form, k) in form_data.unit_peserta"
                  :key="k"
                >
                  <div class="col-md-5">
                    <b-form-group
                      class="mb-3"
                      label="Unit Peserta"
                      label-for="ptl"
                    >
                      <v-select
                        :options="option_unit"
                        label="m_unit_slug"
                        v-model="selected_unit_peserta[k]"
                        placeholder="-Pilih Unit-"
                        @search="onSearchUnit"
                        @update:modelValue="onChangeUnitPeserta(k)"
                      ></v-select>
                    </b-form-group>
                  </div>
                  <div class="col-md-5">
                    <b-form-group
                      class="mb-3"
                      label="Peserta Diundang"
                      label-for="pesertaDiundang"
                    >
                      <b-form-input
                        id="pesertaDiundang"
                        placeholder="Masukkan jumlah peserta diundang"
                        type="text"
                        v-model="form_data.unit_peserta[k].jumlah_diundang"
                      ></b-form-input>
                    </b-form-group>
                  </div>
                  <div
                    class="col-md-2 d-flex align-items-center"
                    v-if="k == 0"
                  >
                    <button
                      class="btn btn-success mt-2"
                      type="button"
                      @click="addFormUnit()"
                    >
                      <span><i class="fa fa-plus"></i> </span>
                    </button>
                  </div>
                  <div
                    class="col-md-2 d-flex align-items-center"
                    v-if="k > 0"
                  >
                    <button
                      class="btn btn-success mt-2"
                      @click="clearFormUnit(k)"
                      type="button"
                    >
                      <span><i class="fa fa-minus"></i> </span>
                    </button>
                  </div>
                </div>
                <!-- Form Tanggal Mulai dan Selesai -->
                <div class="row">
                  <div class="col-md-6">
                    <b-form-group
                      class="mb-3"
                      label="Tanggal Mulai"
                      label-for="tgl-mulai"
                    >
                      <b-form-input
                        id="tgl-mulai"
                        type="date"
                        v-model="form_data.j_tanggal_mulai"
                      ></b-form-input>
                    </b-form-group>
                  </div>
                  <div class="col-md-6">
                    <b-form-group
                      class="mb-3"
                      label="Tanggal Selesai"
                      label-for="tgl-selesai"
                    >
                      <b-form-input
                        id="tgl-selesai"
                        placeholder="Tanggal Selesai"
                        type="date"
                        v-model="form_data.j_tanggal_sampai"
                        @update:modelValue="onChangeTglSampai(form_data.j_tanggal_sampai)"
                      ></b-form-input>
                    </b-form-group>
                  </div>
                </div>
                <!-- Form Jam -->
                <div class="row">
                  <div class="col-md-6">
                    <b-form-group
                      class="mb-3"
                      label="Jam Mulai"
                      label-for="form-jam-mulai"
                    >
                      <b-form-input
                        id="form-jam-mulai"
                        type="time"
                        v-model="form_data.j_jam_mulai"
                      ></b-form-input>
                    </b-form-group>
                  </div>
                  <div class="col-md-6">
                    <b-form-group
                      class="mb-3"
                      label="Jam Selesai"
                      label-for="form-jam-selesai"
                    >
                      <b-form-input
                        id="form-jam-selesai"
                        type="time"
                        v-model="form_data.j_jam_sampai"
                      ></b-form-input>
                    </b-form-group>
                  </div>
                </div>
                <!-- mulai dan akhir penilaian -->
                <div class="row">
                  <div class="col-md-6">
                    <b-form-group
                      class="mb-3"
                      label="Mulai Penilaian"
                      label-for="form-jam-mulai"
                    >
                      <b-form-input
                        id="form-jam-mulai"
                        type="date"
                        v-model="form_data.j_mulai_penilaian"
                      ></b-form-input>
                    </b-form-group>
                  </div>
                  <div class="col-md-6">
                    <b-form-group
                      class="mb-3"
                      label="Jam Selesai"
                      label-for="form-jam-selesai"
                    >
                      <b-form-input
                        id="form-jam-selesai"
                        type="date"
                        v-model="form_data.j_akhir_penilaian"
                      ></b-form-input>
                    </b-form-group>
                  </div>
                </div>
                <!-- Form Adverse Impact -->
                <!-- <div class="row">
                  <div class="col-md-12">
                    <b-form-group class="mb-3" label="Adverse Impact" label-for="form-adverse">
                      <v-select :options="['Yes', 'No']" v-model="form_data.j_impact"
                        placeholder="-Pilih Adverse impact-"></v-select>
                    </b-form-group>
                  </div>
                </div> -->

                <!-- Form Keterangan -->
                <div class="row">
                  <div class="col-md-12">
                    <b-form-group
                      class="mb-3"
                      label="Keterangan"
                      label-for="keterangan"
                    >
                      <b-form-textarea
                        id="keterangan"
                        type="textarea"
                        v-model="form_data.j_keterangan"
                      ></b-form-textarea>
                    </b-form-group>
                  </div>
                </div>
                <!-- Form Agreement -->
                <div class="row">
                  <div class="col-md-12">
                    <b-form-group
                      class="mb-3"
                      label="Agreement"
                      label-for="agreement"
                    >
                      <b-form-textarea
                        id="agreement"
                        type="textarea"
                        v-model="form_data.j_agreement"
                      ></b-form-textarea>
                    </b-form-group>
                  </div>
                </div>
              </div>
              <div class="col-1"></div>
              <div class="col-5">
                <!-- Form Kegiatan -->
                <div class="row">
                  <div class="col-md-12">
                    <b-form-group
                      class="mb-3"
                      label="Kegiatan"
                      label-for="form-unit-pelaksana"
                    >
                      <v-select
                        :options="option_kegiatan"
                        label="kegiatan"
                        v-model="selected_kegiatan"
                        placeholder="-Pilih Kegiatan-"
                        @search="onSearchKegiatan"
                        @update:modelValue="onChangeKegiatan()"
                      ></v-select>
                    </b-form-group>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-12">
                    <b-form-group
                      class="mb-3"
                      label="Sub Kegiatan"
                      label-for="form-unit-pelaksana"
                    >
                      <v-select
                        :options="option_sub_kegiatan"
                        label="nama_subkegiatan"
                        v-model="selected_sub_kegiatan"
                        placeholder="-Pilih Sub Kegiatan-"
                        @search="onSearchSubKegiatan"
                        @update:modelValue="onChangeSubKegiatan()"
                      ></v-select>
                    </b-form-group>
                  </div>
                </div>

                <!-- Form Jenis Asesmen -->
                <div class="row">
                  <div class="col-md-12">
                    <b-form-group
                      class="mb-3"
                      label="Jenis Asesmen"
                      label-for="form-jenis-asesmen"
                    >
                      <v-select
                        :options="option_jenis_asesmen"
                        label="nama_jenisasesmen"
                        v-model="seleceted_jenis_asesmen"
                        placeholder="-Pilih Jenis Asesmen-"
                        @search="onSearchJenisAsesmen"
                        @update:modelValue="onChangeJenisAsesmen()"
                        :disabled="readOnlyJA"
                      ></v-select>
                    </b-form-group>
                  </div>
                </div>
                <!-- Form Tipe AC -->
                <div class="row">
                  <div class="col-md-12">
                    <b-form-group
                      class="mb-3"
                      label="Tipe AC "
                      label-for="form-tipe"
                    >
                      <v-select
                        :options="['Kompetensi & Potensi', 'Kompetensi', 'Potensi']"
                        v-model="form_data.j_tipe"
                        placeholder="-Pilih Tipe AC-"
                      ></v-select>
                    </b-form-group>
                  </div>
                </div>
                <!-- Form Batre Test -->
                <div class="row">
                  <div class="col-md-12">
                    <b-form-group
                      class="mb-3"
                      label="Batre Test"
                      label-for="form-batre-test"
                    >
                      <v-select
                        :options="option_batre_test"
                        label="bt_nama"
                        v-model="seleceted_batre_test"
                        placeholder="-Pilih Batre Test-"
                        @search="onSearchBatreTest"
                        @update:modelValue="onChangeBatreTest()"
                      ></v-select>
                    </b-form-group>
                  </div>
                </div>
                <!-- Form Alat Tes -->
                <div
                  class="row row-cols-2"
                  v-if="seleceted_batre_test != ''"
                >
                  <b-form-group
                    class="mb-3"
                    label="Metode"
                    label-for="form-metode"
                    v-for="alattes in option_alat_test"
                    :key="alattes.m_alat_tes_id"
                  >
                    <b-form-input
                      id="form-metode"
                      class="bg-secondary text-white"
                      type="text"
                      readonly
                      v-model="alattes.m_alat_tes_nama"
                    ></b-form-input>
                  </b-form-group>
                </div>
                <!-- Form Materi -->
                <div class="row">
                  <div class="col-md-12">
                    <b-form-group
                      class="mb-3"
                      label="Materi"
                      label-for="form-materi"
                    >
                      <v-select
                        :options="option_materi"
                        label="mm_nama"
                        v-model="seleceted_materi"
                        placeholder="-Pilih Materi-"
                        @search="onSearchMateri"
                        @update:modelValue="onChangeMateri()"
                        :disabled="readOnlyMateri"
                      ></v-select>
                    </b-form-group>
                  </div>
                </div>
                <!-- Form Bentuk Dinamika -->
                <div class="row">
                  <div class="col-md-12">
                    <b-form-group
                      class="mb-3"
                      label="Bentuk Dinamika "
                      label-for="form-bentuk-dinamika"
                    >
                      <v-select
                        :options="['cluster', 'kompetensi']"
                        v-model="form_data.j_bentuk_dinamika"
                        placeholder="-Pilih Bentuk Dinamika-"
                      ></v-select>
                    </b-form-group>
                  </div>
                </div>
                <!-- Form Bentuk Penilaian -->
                <div class="row">
                  <div class="col-md-12">
                    <b-form-group
                      class="mb-3"
                      label="Bentuk Penilaian "
                      label-for="form-bentuk-penilaian"
                    >
                      <v-select
                        :options="['level', 'rating']"
                        v-model="form_data.j_bentuk_penilaian"
                        placeholder="-Pilih Bentuk Penilaian-"
                      ></v-select>
                    </b-form-group>
                  </div>
                </div>

                <div
                  class="row row-cols-2"
                  v-if="seleceted_jenis_asesmen != ''"
                >
                  <b-form-group
                    class="mb-3"
                    label="Checklist"
                    label-for="form-metode"
                    v-for="checklist in form_data.checklist"
                    :key="checklist.id_ca"
                  >
                    <b-form-input
                      id="form-metode"
                      class="bg-secondary text-white"
                      type="text"
                      readonly
                      v-model="checklist.nama_checklist"
                    ></b-form-input>
                  </b-form-group>
                </div>
                <div
                  class="row"
                  v-for="(form, k) in form_data.form_petugas"
                  :key="k"
                >
                  <div class="col-md-5">
                    <b-form-group
                      class="mb-3"
                      label="Petugas"
                      label-for="ptl"
                    >
                      <v-select
                        :options="option_ptl"
                        label="label"
                        v-model="selected_petugas[k]"
                        placeholder="-Pilih Petugas-"
                        @search="onSearchPTL"
                        @update:modelValue="onChangePetugas(k)"
                      ></v-select>
                    </b-form-group>
                  </div>
                  <div class="col-md-5">
                    <b-form-group
                      class="mb-3"
                      label="Tugas"
                      label-for="tugas"
                    >
                      <v-select
                        :options="option_tugas"
                        v-model="selected_tugas[k]"
                        label="tugas_txt"
                        placeholder="-Pilih Tugas-"
                        @update:modelValue="onChangeTugas(k)"
                      ></v-select>
                    </b-form-group>
                  </div>
                  <div
                    class="col-md-2 d-flex align-items-center"
                    v-if="k == 0"
                  >
                    <button
                      class="btn btn-success mt-2"
                      type="button"
                      @click="addForm()"
                    >
                      <span><i class="fa fa-plus"></i> </span>
                    </button>
                  </div>
                  <div
                    class="col-md-2 d-flex align-items-center"
                    v-if="k > 0"
                  >
                    <button
                      class="btn btn-success mt-2"
                      @click="clearForm(k)"
                      type="button"
                    >
                      <span><i class="fa fa-minus"></i> </span>
                    </button>
                  </div>
                </div>
                <div
                  class="col-md-12"
                  v-if="is_show_opsi_job_target"
                >
                  <b-form-group
                    class="mb-3"
                    label="Pilih Job Target"
                    label-for="lokasi"
                  >
                    <div class="form-check form-check-inline">
                      <input
                        class="form-check-input"
                        type="radio"
                        id="inlineCheckbox1"
                        value="1"
                        v-model="form_data.is_checked_job"
                        @change="checkJobTarget($event)"
                      >
                      <label
                        class="form-check-label"
                        for="inlineCheckbox1"
                      >Yes</label>
                    </div>
                    <div class="form-check form-check-inline">
                      <input
                        class="form-check-input"
                        type="radio"
                        id="inlineCheckbox2"
                        value="0"
                        v-model="form_data.is_checked_job"
                        @change="checkJobTarget($event)"
                      >
                      <label
                        class="form-check-label"
                        for="inlineCheckbox2"
                      >No</label>
                    </div>
                  </b-form-group>
                </div>
                <div v-if="is_show_job_target_list">
                  <div
                    class="row"
                    v-for="(value_jobtarget, key_jobtarget) in form_data.form_jobtarget"
                    :key="key_jobtarget"
                  >
                    <div class="col-md-10">
                      <b-form-group
                        class="mb-3"
                        label="Job Target"
                      >
                        <v-select
                          :options="option_jobtarget"
                          v-model="selected_jobtarget[key_jobtarget]"
                          label="nama"
                          placeholder="-Pilih Job Target-"
                          @update:modelValue="onChangeJobTarget(key_jobtarget)"
                        ></v-select>
                      </b-form-group>
                    </div>
                    <div
                      class="col-md-2 d-flex align-items-center"
                      v-if="key_jobtarget == 0"
                    >
                      <button
                        class="btn btn-success mt-2"
                        type="button"
                        @click="addFormJobTarget()"
                      >
                        <span><i class="fa fa-plus"></i> </span>
                      </button>
                    </div>
                    <div
                      class="col-md-2 d-flex align-items-center"
                      v-if="key_jobtarget > 0"
                    >
                      <button
                        class="btn btn-danger mt-2"
                        @click="clearFormJobTarget(key_jobtarget)"
                        type="button"
                      >
                        <span><i class="fa fa-minus"></i> </span>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="row col-5 text-end">
              <button
                class="btn btn-success mt-2"
                type="button"
                @click="validasi()"
              >
                <span><i class="fa fa-plus"></i> Tambah Data</span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-4">
        <div class="card">
          <div class="card-body">
            <div class="row">
              <h5>Metode</h5>
            </div>
            <div class="row">
              <div class="col-md-12">
                <b-form-group
                  class="mb-3"
                  label-for="form-metode"
                >
                  <b-form-input
                    id="form-metode"
                    class="bg-secondary text-white"
                    type="text"
                    readonly
                    v-model="form_data.j_metode"
                  ></b-form-input>
                </b-form-group>
              </div>
            </div>
            <div class="row">
              <div class="col-md-12">
                <b-form-group
                  class="mb-3"
                  label="Nama Grup"
                  label-for="form-metode"
                >
                  <b-form-input
                    id="form-metode"
                    type="text"
                    v-model="nama_grup"
                  ></b-form-input>
                </b-form-group>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-8">
        <div class="card">
          <div class="card-body">
            <div class="row">
              <h5>List Jadwal Grup</h5>
            </div>

            <div class="table-responsive bg-white">
              <table class="table table-bordered mb-0 mt-2">
                <thead
                  class="bg-dark text-white"
                  style="padding: 10px"
                >
                  <th style="padding: 5px">No</th>
                  <th style="padding: 5px">Data Jadwal</th>
                  <th style="padding: 5px">Petugas & Tugas</th>
                  <th style="padding: 5px">Aksi</th>
                </thead>
                <tbody>
                  <tr
                    v-for="(form, index) in form_data_fix"
                    :key="index"
                  >
                    <td>{{ index + 1 }}</td>
                    <td>
                      Unit Pelaksana : <b>{{ form.j_txt_unit }}</b><br />
                      <!-- Unit Client : {{ form.j_txt_unit }}<br /> -->
                      Kegiatan : <b>{{ form.j_txt_kegiatan }}</b><br />
                      Jenis Asesmen : <b>{{ form.j_txt_dirkom }}</b><br />
                      Materi : <b> {{ form.j_materi }}</b><br />
                      Tipe ac : <b> {{ form.j_tipe }}</b><br />
                      <!-- Standar : <b> {{ form.j_txt_unit }}</b><br /> -->
                      Adverse Impact : <b> {{ form.j_impact }}</b><br />
                      Bentuk Dinamika : <b> {{ form.j_bentuk_dinamika }}</b><br />
                      Bentuk Penilaian : <b> {{ form.j_bentuk_penilaian }}</b><br />
                      PTL : <b> {{ form.j_txt_ptl }}</b><br />
                      Tanggal Mulai : <b> {{ form.j_tanggal_mulai }}</b><br />
                      Tanggal Selesai : <b> {{ form.j_tanggal_sampai }}</b><br />
                      Jam Mulai : <b> {{ form.j_jam_mulai }}</b><br />
                      Jam Selesai : <b> {{ form.j_jam_sampai }}</b><br />
                      Mulai Penilaian : <b> {{ form.j_mulai_penilaian }}</b><br />
                      Akhir Penilaian : <b> {{ form.j_akhir_penilaian }}</b><br />
                      Catatan : <b> {{ form.j_keterangan }}</b><br />
                      Agreement : <b> {{ form.j_agreement }}</b><br />
                      Checklist :
                      <b><span
                          v-for="(form_chklst, index) in form.checklist"
                          :key="index"
                        >
                          {{ form_chklst.nama_checklist }}
                          <span v-if="index != form.checklist.length - 1"> ,&nbsp; </span>
                        </span></b>
                    </td>
                    <td>
                      <span
                        v-for="(form_ptgs, index) in form.form_petugas"
                        :key="index"
                      >
                        {{ form_ptgs.jp_txt_petugas }}
                        <span v-if="index != form.form_petugas.length - 1"> ,&nbsp; </span>
                      </span>
                    </td>
                    <td>
                      <div class="btn-group">
                        <button
                          type="button"
                          class="btn btn-danger btn-sm"
                          v-on:click="removeCart(index)"
                        ><i class="fa fa-trash"></i> Hapus</button>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="row col-lg-12">
        <button
          class="btn btn-primary mt-2"
          type="button"
          @click="storeDataCombo()"
        >
          <span><i class="fa fa-plus"></i> Simpan Jadwal</span>
        </button>
      </div>
    </div>
  </Layout>
</template>
